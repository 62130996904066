import http from '../utils/http'
import conf from '../config'

export const API_ROOT = `${conf.API_URL}`

const faqServices = {
    findFaqs() {
        return http.get(`${API_ROOT}/faqs`).then(resp => {
            return resp
        })
    },
    updateFaq(payload) {
        return http.put(`${API_ROOT}/faqs/${payload.faq_id}`, payload).then(resp => {
            return resp
        })
    },
    createFaq(payload) {
        return http.post(`${API_ROOT}/faqs`, payload).then(resp => {
            return resp
        })
    },
    deleteFaq(payload) {
        return http.delete(`${API_ROOT}/faqs/${payload.faq_id}`,).then(resp => {
            return resp
        })
    },
    updateFaqsOrder(payload) {
        return http.put(`${API_ROOT}/faqs/order`, payload).then(resp => {
            return resp
        })
    }
}

export default faqServices
